import { createSlice } from '@reduxjs/toolkit'

export const slidingTextSlice = createSlice({
    name: 'slidingText',
    initialState: {
      value: [],
    },
    reducers: {
        setSlidingText: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setSlidingText } = slidingTextSlice.actions;

export default slidingTextSlice.reducer;
