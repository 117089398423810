import { createSlice } from '@reduxjs/toolkit'

export const topCrasherSlice = createSlice({
    name: 'topCrasher',
    initialState: {
      value: [],
    },
    reducers: {
        setTopCrasher: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setTopCrasher } = topCrasherSlice.actions;

export default topCrasherSlice.reducer;
